<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'Why is it recommended to add a wide-mouth funnel on top of the Erlenmeyer flask while heating solvent for a recrystallization?'
            : "Pourquoi est-il recommandé d'ajouter un entonnoir à large ouverture sur l'erlenmeyer pendant le chauffage du solvant d'une recristallisation ?"
        }}
      </p>
      <!--  éè-->

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab4Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'To avoid the solvent bumps when boiling',
          value: 'avoidBumping',
        },
        {
          text: 'To maximize heating',
          value: 'maximizeHeating',
        },
        {
          text: 'To easily add more solvent',
          value: 'easilyAddMoreSolvent',
        },
        {
          text: 'To minimize the loss of solvent',
          value: 'minimizeEvaporation',
        },
      ],
      // éè
      optionsFr: [
        {
          text: 'Pour éviter les éclaboussures du solvant en ébullition',
          value: 'avoidBumping',
        },
        {
          text: 'Pour maximiser le chauffage du solvant',
          value: 'maximizeHeating',
        },
        {
          text: 'Pour ajouter plus facilement plus de solvant',
          value: 'easilyAddMoreSolvent',
        },
        {
          text: "Pour minimiser l'évaporation du solvant",
          value: 'minimizeEvaporation',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
